.home {
  $h:&;

  background: $lightestGray;

  &__hero {
    .slick-slide {
      height: 90vh;

      > div {
        height: 90vh;
      }
    }

    // .slick-slider {
      // padding-bottom: 100px;
    // }

    .slick-next,
    .slick-prev {
      bottom: 0;
      top: auto;
      width: 60px;
      height: 60px;
      &:before {
        content: '';
      }
    }

    .slick-next {
      right: 100px;
      background: url('/images/arrow-right.svg') no-repeat center center / cover;
    }

    .slick-prev {
      background: url('/images/arrow-left.svg') no-repeat center center / cover;
      left: 100px;
    }

    .slick-dots {
      li {
        button {
          @include trans;
          border: 1px solid $white;
          position: relative;
          overflow: hidden;

          &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0) rotate(-45deg);
            height: 150%;
            width: 1px;
            background: $white;
            opacity: 1;
          }

          &:hover {
            background: $white;
          }
        }
        
        &.slick-active {
          button {
            background: $white;
          }
        }
      }
    }

    &__slide {
      padding-top: 15vh;
      position: relative;
      height: 100%;

      &--light {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: linear-gradient(to bottom right, #000, transparent);
          opacity: .7;
          z-index: 1;
        }
      }

      &--dark {
        #{$h}__hero__title {
          color: $black;
          -webkit-text-stroke: 2px $black;
          mix-blend-mode: normal;
        }

        #{$h}__hero__descripcion {
          color: $black;
        }
      }
    }

    &__slide-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__title {
      text-transform: uppercase;
      font-family: $heroTitleFont;
      color: #000;
      mix-blend-mode: screen;
      -webkit-text-stroke: 1px $white;
      font-size: 50px;
      margin-bottom: 40px;
      margin: 0;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      background: url('/images/transparent.png') repeat center center;
      z-index: 2;
      position: relative;

      @include mq(md) {
        padding-left: 10px;
        font-size: 110px;
      }
    }

    &__descripcion {
      color: $white;
      font-size: 40px;
      text-transform: uppercase;
      margin: 0;
      display: inline-block;
      position: relative;
      z-index: 2;

      @include mq(md) {
        font-size: 80px;
      }
    }

    &__button {
      display: flex;
      white-space: nowrap;
      text-transform: none;
      justify-content: center;
      padding: 5px 20px;
      background: $black;
      align-items: center;
      color: $white;
      text-decoration: none;
      z-index: 999;
      font-family: $altFont;
      font-size: 20px;
      margin-top: 40px;
      font-weight: 700;
      z-index: 2;
      position: relative;

      @include mq(md) {
        position: absolute;
        top: 60%;
        left: 55%;
        transform: translate3d(-50%, -50%, 0);
      }

      .icon {
          margin-left: 10px;

          svg {
              @include icon(30px, 'rightup');
          }
      }
    }
  }

  &__eventos {
    .-divider {
      padding-bottom: 50px;
      margin-bottom: 50px;
      border-bottom: 1px solid $black;
    }

    .banner {
      margin-bottom: 50px;
    }

    &__destacado {
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid $black;
    }

    &__button {
      display: flex;
      justify-content: center;
      padding: 5px 20px;
      background: $black;
      align-items: center;
      color: $white;
      text-decoration: none;
      font-size: 16px;
      font-family: $altFont;
      white-space: nowrap;

      .icon {
        margin-left: 10px;

        svg {
            @include icon(30px, 'rightup');
        }
      }
    }
  }

  &__calendario {
    padding-bottom: 50px;

    .banner {
      margin-bottom: 50px;
    }
  }

  &__newsletter {
    padding-bottom: 50px;
  }
}
