  .article {
  $a: &;

  width: 100%;
  text-decoration: none;

  @include mq(md) {
    display: flex;
    flex-wrap: nowrap;
  }

  &__image {
    width: 100%;
    height: 0;
    padding-bottom: 30%;
    overflow: hidden;
    position: relative;

    @include mq(md) {
      width: 50%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__inscripcion {
    position: absolute;
    left: 0;
    bottom: 20px;
    padding: 5px 20px;
    color: $black;
    background: $yellow;
    text-transform: uppercase;
    font-size: 28px;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    padding: 20px 0;

    @include mq(md) {
      width: 50%;
      padding: 0 20px;
    }
  }

  &__category,
  &__date {
    color: $black;
    font-size: 16px;
    font-family: $altFont;

    @include mq(md) {
      font-size: 20px;
    }
  }

  &__title {
    color: $black;
    font-size: 30px;
    text-transform: uppercase;
    margin-top: 10px;

    @include mq(md) {
      font-size: 60px;
    }
  }

  &__description {
    color: $black;
    font-size: 16px;
    font-family: $altFont;
    margin-top: 10px;
    line-height: 1.3;
    letter-spacing: 2px;

    @include mq(md) {
      font-size: 20px;
    }
  }

  &.-eventos,
  &.-convocatoria,
  &.-episodios {
    display: block;

    #{$a}__image {
      width: 100%;
      padding-bottom: 120%;
    }

    #{$a}__content {
      width: 100%;
      padding: 20px 0;
      
      @include mq(md) {
        min-height: 160px;
      }
    }

    #{$a}__category,
    #{$a}__date {
      color: $black;
      font-size: 16px;
      font-family: $altFont;
    }
  
    #{$a}__title {
      color: $black;
      font-size: 36px;
      text-transform: uppercase;
    }
  }

  &.-convocatoria {
    #{$a}__image {
      padding-bottom: 60%; 
    }
  }

  &.-episodios {
    position: relative;

    #{$a}__image {
      padding-bottom: 70%; 
    }

    #{$a}__content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0; 
      display: flex;
      justify-content: flex-end;
      padding: 20px;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: linear-gradient(to top, $black, transparent);
      }
    }

    #{$a}__title {
      position: relative;
      z-index: 2;
      color: $white;
      font-family: $altFont;
      text-transform: none;
      font-weight: 500;
      font-size: 24px;
    }

    #{$a}__description {
      position: relative;
      z-index: 2;
      color: $white;
      font-family: $altFont;
      text-transform: none;
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
