.newsletter {
  $n: &;

  &__container {
    background: $white;
    padding: 20px;
    position: relative;
  }

  &__content {
    width: 100%;
  }

  &__button {
    display: inline-flex;
    justify-content: center;
    padding: 5px 20px;
    background: $black;
    align-items: center;
    color: $white;
    text-decoration: none;
    border: 1px solid $black;
    font-size: 20px;
    font-family: $altFont;
    width: 70%;

    @include mq(md) {
      width: auto;
    }

    .icon {
      margin-left: 10px;

      svg {
        @include icon(30px, 'rightup')
      }
    }
  }

  &__subtitle {
    margin: 0;
    text-transform: uppercase;
    font-size: 20px;

    @include mq(md) {
      font-size: 40px;
    }
  }

  &__title {
    margin: 0;
    text-transform: uppercase;
    font-size: 24px;
    line-height: .7;
    margin-bottom: 80px;
    font-family: $heroTitleFont;

    @include mq(md) {
      font-size: 60px;
    }
  }

  &__mail-group {
    width: 100%;
    
    @include mq(md) {
      height: 52px;
      display: flex;
      width: auto;
    }

    input {
      border: 1px solid $black;
      display: block;
      background: $white;
      padding: 0 20px;
      font-family: $altFont;
      width: 70%;
      height: 52px;
      outline: none;

      @include mq(md) {
        width: 300px;
        height: 100%;
      }
    }
  }

  img{
    width: 70px;
    height: 70px;
    position: absolute;
    right: 30px;
    bottom: 20px;

    @include mq(md) {
      width: 70px;
      height: 70px;
      position: absolute;
      right: 30px;
      top: 20px;
    }
  }

  &.nave-tv {
    background: $yellow;
    
    #{$n}__container {
      padding: 20px 0;
      background: transparent;

      @include mq(md) {
        padding: 80px 0;
      }
    }

    #{$n}__button {
      padding: 15px 40px;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 500;
      .icon {
        display: none;
      }
    }

    #{$n}__subtitle {
      font-family: $altFont;
      color: $white;
      font-size: 18px;
      margin-bottom: 20px;

      @include mq(md) {
        font-size: 24px;
      }
    }

    #{$n}__title {
      font-family: $altFont;
      color: $white;
      font-size: 60px;
      text-transform: none;
      margin-bottom: 40px;

      @include mq(md) {
        font-size: 80px;
      }
    }

    #{$n}__mail-group {
      input {
        border: 1px solid $white;
      }
    }


    img {
      width: 70px;
      height: 70px;
      position: absolute;
      right: 15px;
      bottom: 20px;
  
      @include mq(md) {
        width: 170px;
        height: 170px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}