.programa {
  background: $lightestGray;

  &__hero {
    background: url('/images/home/hero.jpg') no-repeat center center / cover;
    @include mq(md) {
      padding-top: 15vh;
    }

    &__container {
      padding: 100px 0;
    }

    h1 {
      text-transform: uppercase;
      font-family: $heroTitleFont;
      color: #000;
      mix-blend-mode: screen;
      -webkit-text-stroke: 1px $white;
      font-size: 50px;
      margin-bottom: 40px;
      margin: 0;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      background: url('/images/transparent.png') repeat center center;
      
      @include mq(md) {
        padding-left: 10px;
        font-size: 110px;
      }
    }

    p {
      color: $white;
      font-size: 40px;
      text-transform: uppercase;
      margin: 0;
      display: inline-block;
      position: relative;

      @include mq(md) {
        font-size: 80px;
      }
    }

    &__buttons {
      display: flex;
      align-items: flex-start;
      margin-top: 20px;
    }

    &__scroll-button {
      background: transparent;
      border: none;
      cursor: pointer;

      .icon {
        margin-right: 40px;

        svg {
            @include icon(60px, 'down');
        }
      }
    }

    &__button {
      display: inline-flex;
      justify-content: center;
      padding: 5px 20px;
      background: $black;
      align-items: center;
      color: $white;
      text-decoration: none;
      font-size: 16px;
      font-family: $altFont;
      white-space: nowrap;
      font-weight: 700;
      
      .icon {
        margin-left: 10px;

        svg {
            @include icon(30px, 'rightup');
        }
      }
    }
  }

  &__details {
    padding-top: 50px;

    &__breadcrumb {
      margin-bottom: 40px;

      ul {
        display: flex;

        li {
          text-transform: uppercase;
          font-family: $altFont;
          color: $darkGray;
          font-size: 12px;
          white-space: nowrap;

          &:not(:last-child) {
            margin-right: 5px;
            padding-right: 5px;
            border-right: 1px solid $darkGray;
          }

          @include mq(md) {
            font-size: 14px;

            &:not(:last-child) {
              margin-right: 10px;
              padding-right: 10px;
            }
          }
        }
      }
    }

    &__content-heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;

      p {
        font-family: $altFont;
        font-weight: 500;
        font-size: 20px;
        width: 100%;
        line-height: 1.5;
        letter-spacing: 2px;
        margin: 0;

        @include mq(md) {
          font-size: 32px;
          width: 65%;
        }
      }
    }

    &__emblem-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      img {
        width: 50px;
        @include mq(md) {
          width: 100%;
          height: auto;
        }
      }
    }

    &__content {
      p {
        font-family: $altFont;
        letter-spacing: 2px;
        line-height: 1.5;
        font-size: 16px;

        @include mq(md) {
          font-size: 20px;
        }
      }

      img {
        width: 100%;
        height: auto;
        margin: 50px 0;
      }
    }
  }

  &__fechas {
    padding-bottom: 50px;
  }
  
  .-divider {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $black;
  }
}
