.coproductor-module {
  $a: &;

  order: 1;
  margin-bottom: 40px;
  text-decoration: none;

  @include mq(md) {
    order: 2;
    margin-bottom: 0;
  }

  &__images {
    width: 100%;
    padding-bottom: 100%;
    width: 100%;
    height: 0;
    overflow: hidden;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  
  &__logo {
    @include trans;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    &:hover {
      opacity: 0;
    }
  }

  &__gif {
    @include trans;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:hover {
      opacity: 1;
    }
  }
  
  &__details {
    text-align: center;
    width: 100%;
  }
  
  &__name {
    font-family: $altFont;
    font-size: 18px;
    font-weight: 500;
  }
}

