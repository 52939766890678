.descubre-mas {
  $n: &;

  background: $lightGray;
  padding: 20px 15px;

  @include mq(md) {
    padding: 40px 45px;
  }

  &__title {
    margin: 0;
    margin-bottom: 20px;
    font-family: $altFont;
    font-size: 40px;
  }

  &__wrapper {
    @include mq(md) {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;      
    }
  }

  &__container {
    width: 100%;
    position: relative;
    margin-bottom: 15px;

    @include mq(md) {
      margin-bottom: 0;
      width: 23%;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(to top, $black, 30%, transparent);
      z-index: 2;
    }
  }

  &__image {
    width: 100%;

    img {
      width: 100%;
    }
  }

  &__content {
    width: 100%;
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 3;
  }

  &__content-title {
    font-size: 30px;
    color: $white;
    font-family: $altFont ;
    margin: 0;
    margin-bottom: 20px;
  }

  &__button {
    display: inline-flex;
    justify-content: center;
    padding: 15px 30px;
    text-transform: uppercase;
    background: $yellow;
    align-items: center;
    color: $black;
    font-weight: 700;
    text-decoration: none;
    border: 1px solid $black;
    font-size: 16px;
    font-family: $altFont;

    .icon {
      margin-left: 10px;

      svg {
        @include icon(30px, 'rightup')
      }
    }
  }
}