.artista-module {
  $a: &;

  order: 1;
  margin-bottom: 40px;
  text-decoration: none;

  @include mq(md) {
    order: 2;
    margin-bottom: 0;
  }

  &__images {
    width: 100%;
  }
  
  &__avatar {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  &__details {
    text-align: center;
    width: 100%;
  }
  
  &__title,
  &__name {
    font-family: $altFont;
    font-size: 18px;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
  }

  &.tacto {
    width: 100%;

    #{$a}__images {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      overflow: hidden;
      margin-bottom: 10px;

      &:hover {
        #{$a}__gif {
          opacity: 1;
        }

        #{$a}__avatar {
          opacity: 0;
        }
      }
    }
    
    #{$a}__avatar,
    #{$a}__gif {
      @include trans;

      border-radius: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    #{$a}__gif {
      opacity: 0;
    }

    #{$a}__details {
      text-align: left;
    }

    #{$a}__title {
      display: none;
    }

    #{$a}__name {
      color: $white;
      font-size: 40px;
      font-family: $mainFont;
      text-transform: uppercase;
    }
  }
}

