.quienes-somos,
.mision,
.fundacion {
  background: $lightestGray;
  margin-top: 60px;

  @include mq(md) {
    margin-top: 0;
  }

  &__hero {
    background: url('/images/quienes-somos/hero.jpg') no-repeat center center / cover;
    @include mq(md) {
      padding-top: 15vh;
    }

    &__container {
      padding: 100px 0;
    }

    &__title {
      text-transform: uppercase;
      font-family: $heroTitleFont;
      color: #000;
      mix-blend-mode: screen;
      -webkit-text-stroke: 1px $white;
      font-size: 50px;
      margin-bottom: 40px;
      margin: 0;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      background: url('/images/transparent.png') repeat center center;
      
      @include mq(md) {
        padding-left: 10px;
        font-size: 110px;
      }
    }
  }

  &__details {
    padding-top: 50px;

    @include mq(md) {
      padding-top: 100px;
    }

    &__main-text {
      margin-bottom: 50px;

      @include mq(md) {
        margin-bottom: 100px;
      }

      p {
        font-family: $altFont;
        font-weight: 500;
        font-size: 18px;
        width: 100%;
        line-height: 1.4;
        letter-spacing: 2px;
        margin: 0;
  
        @include mq(md) {
          font-size: 34px;
          width: 75%;
        }
      }
    }

    &__image {
      width: 100%;
      height: 0;
      position: relative;
      overflow: hidden;
      padding-bottom: 80%;
      margin-bottom: 50px;

      @include mq(md) {
        padding-bottom: 65%;
        margin-bottom: 100px;
      }

      &--margin {
        margin-bottom: 30px;

        @include mq(md) {
          margin-bottom: 100px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }

    &__text-container {
      width: 100%;
      margin-bottom: 50px;

      @include mq(md) {
        margin-bottom: 100px;
      }
    }

    &__text {
      font-family: $altFont;
      line-height: 1.5;
      letter-spacing: 2px;
      font-size: 16px;
      margin: 0;
      margin-bottom: 20px;
      font-weight: 300;

      @include mq(md) {
        margin-bottom: 0;
      }

      &:not(:last-child) {
        @include mq(md) {
          margin-bottom: 20px;
        }
      }

      @include mq(md) {
        font-size: 18px;
      }
    }

    &__destacado {
      margin-bottom: 50px;

      @include mq(md) {
        margin-bottom: 100px;
      }
    }

    &__destacado-text {
      font-family: $altFont;
      line-height: 1.5;
      letter-spacing: 2px;
      font-weight: 500;
      font-size: 20px;
      text-align: center;

      @include mq(md) {
        font-size: 30px;
      }
    }
  }

  &__video {
    z-index: 1;

    &__container {
      width: 100%;
      height: 0;
      padding-bottom: 56.5%;
      overflow: hidden;
      margin-bottom: -5px;
      position: relative;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &__play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 50px;
      height: 50px;
      background: none;
      border: none;
      cursor: pointer;
      outline: none;

      @include mq(md) {
        width: 100px;
        height: 100px;
      }
    }
  }
}

.mision {
  &__hero {
    background: url('/images/mision/hero.jpg') no-repeat center center / cover;
  }
}

.fundacion {
  &__hero {
    background: url('/images/fundacion/hero.jpg') no-repeat center center / cover;

    &__title {
      font-size: 30px;

      @include mq(md) {
        font-size: 110px;
      }
    }
  }

  &__details {
    &__text-container {
      margin-bottom: 0;
    }

    &__image {
      overflow: visible;
      width: 100%;
      padding-bottom: 0;
      min-height: 100vw;      
      overflow: hidden;

      @include mq(md) {
        min-height: 100%;
        width: 90%;
        margin-right: auto;
      }

      img {
        position: relative;
        height: auto;
        width: 100%;

        @include mq(md) {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  &__donaciones {
    padding: 50px 0;
    position: relative;

    @include mq(md) {
      padding: 100px 0;
    }

    img {
      position: absolute;
      bottom: 20px;
      right: 35px;
      width: 50px;

      @include mq(md) {
        bottom: auto;
        top: 40px;
        right: 40px;
        width: 70px;
      }
    }

    &__container {
      background: $white;
      padding: 20px;

      @include mq(md) {
        padding: 40px;
      }
    }

    &__heading {
      margin-bottom: 20px;

      @include mq(md) {
        margin-bottom: 40px;
      }
    }

    &__title {
      margin: 0;
      font-size: 26px;
      text-transform: uppercase;
      font-family: $heroTitleFont;
      line-height: .7;
      margin-bottom: 5px;

      @include mq(md) {
        font-size: 60px;
      }
    }

    &__subtitle {
      margin: 0;
      font-size: 24px;
      text-transform: uppercase;

      @include mq(md) {
        font-size: 40px;
      }
    }

    &__text {
      font-size: 18px;
      font-family: $altFont;
      width: 85%;
      margin: 0;
      margin-bottom: 20px;

      @include mq(md) {
        margin-bottom: 40px;
        width: 65%;
        font-size: 20px;
      }
    }

    &__button {
      display: inline-flex;
      justify-content: center;
      padding: 5px 10px 5px 20px;
      background: $black;
      align-items: center;
      color: $white;
      text-decoration: none;
      border: 1px solid $black;
      font-size: 20px;
      font-family: $altFont;

      .icon {
        margin-left: 60px;
  
        svg {
          @include icon(30px, 'rightup')
        }
      }
    }
  }
}

