.header {
    $h: &;

    @include trans;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 1000;
    height: 10vh;
    background: $black;

    @include mq(md) {
        height: 115px;
        background: transparent;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 10vh;
        padding: 15px 0;
        
        @include mq(md) {
            padding: 20px 0 0;
            height: 115px;
        }
    }

    &__logo {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        height: 100%;
        width: auto;
        z-index: 1001;

        img {
            height: 35px;
            
            @include mq(md) {
                height: 70px;
                padding-bottom: 20px;
            }
        }
    }

    &__nav {
        @include trans;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        transform: translateX(-100%);
        background: $black;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 15px;
        z-index: 1000;
        
        @include mq(md) {
            height: 100%;
            position: static;
            top: auto;
            left: auto;
            width: auto;
            transform: translateX(0);
            background: transparent;
            padding: 0;
            justify-content: space-between;
        }
    }

    &__nav-buttons {
        display: flex;
        width: 100%;
        order: 2;
        flex-direction: column;
        
        @include mq(md){
            order: 1;
            justify-content: flex-end;
            flex-direction: row;
        }
    }

    &__nav-button {
        display: flex;
        justify-content: center;
        padding: 0px 15px;
        background: $yellow;
        align-items: center;
        color: $white;
        margin-bottom: 10px;
        text-decoration: none;
        font-family: $altFont;
        font-weight: 700;

        &:hover {
            color: $white - #111;
            background: $yellow - #111;
        }

        @include mq(md) {
            margin-left: 15px;
            margin-bottom: 0;
            font-size: 16px;
        }

        .icon {
            margin-left: 10px;

            svg {
                @include icon(30px, 'rightup');
            }
        }
    }

    &__nav-list {
        order: 1;
        margin-bottom: 10px;
        padding-top: 10vh;
        margin-top: 10vh;
        width: 100%;
        height: 80vh;
        overflow-y: scroll;

        @include mq(md) {
            display: flex;
            order: 2;
            margin-bottom: 0;
            padding-top: 0;
            margin-top: 0;
            height: auto;
            overflow-y: visible;
        }
    }

    &__nav-item {
        margin-bottom: 10px;
        position: relative;
        color: $white;
        text-decoration: none;
        font-size: 26px;
        font-family: $altFont;
        font-weight: 700;
        cursor: pointer;

        @include mq(md) {
            margin-bottom: 0;
            padding: 20px 0;
            font-size: 14px;
        }

        &:not(:first-child) {
            @include mq(md) {
                margin-left: 30px;
            }
        }

        > a {
            color: $white;
            text-decoration: none;
            font-size: 26px;
            font-family: $altFont;
            font-weight: 700;

            @include mq(md) {
                font-size: 14px;
            }
        }

        &:hover {
            #{$h}__nav-sublist {
                opacity: 1;
                pointer-events: all;
                min-height: 10px;
            }

            #{$h}__nav-subitem {
                opacity: 1;
                height: 40px;

                &--big {
                    height: 60px;

                    @include mq(md) {
                        height: 40px;
                    }
                }
            }
        }
    }

    &__expand-button {
        width: 15px;
        height: 15px;
        position: absolute;
        right: 5px;
        top: 5px;
        transform: rotate(45deg);
        border: none;
        border-right: 2px solid $white;
        border-bottom: 2px solid $white;
        background: transparent;
        outline: none;
        
        @include mq(md) {
            display: none;
        }
    }

    &__nav-sublist {
        @include trans;
        background: $black;
        pointer-events: none;
        overflow: hidden;
        min-height: 0;
        
        @include mq(md) {
            position: absolute;
            top: 100%;
            left: -20px;
        }
    }

    &__nav-subitem {
        @include trans;
        height: 0;
        padding: 0 20px;
        opacity: 0;
        display: flex;
        align-items: center;

        @include mq(md) {
            white-space: nowrap;
        }

        a {
            color: $white;
            text-decoration: none;
            font-size: 20px;
            font-family: $altFont;
            font-weight: 700;

            @include mq(md) {
                font-size: 14px;
            }        
        }
    }

    &.-scrolled {
        background: $black;
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        z-index: 1001;

        @include mq(md) {
            display: none;
        }

         span {
            background-color: $mainColor;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
            transform: translateX(0);
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
