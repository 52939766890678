.nave-tv {
  background: $lightestGray;
  overflow: hidden;

  &__hero {
    // background: url('/images/nave-tv/hero.jpg') no-repeat center center / cover;
    &__container {
      width: 100%;
      position: relative;
    }

    &__slider {
      width: 100%;
      height: 90vh;

      .slick-next,
      .slick-prev {
        display: none !important;
      }
    }

    .slider-controls {
      position: absolute;
      right: 50px;
      transform: translateY(-50%);
      bottom: 400px;
      height: 50px;
      z-index: 2000;
      justify-content: space-between;
      display: none;

      @include mq(md) {
        display: flex;
      }
      
      .-js-arrow-next {
        background: url('/images/arrow-right.svg') no-repeat center center / cover;
        width: 50px;
        height: 100%;
        margin-left: 40px;
        cursor: pointer;
      }
      
      .-js-arrow-prev {
        margin-right: 40px;
        height: 100%;
        background: url('/images/arrow-left.svg') no-repeat center center / cover;
        width: 50px;
        cursor: pointer;
      }
    }

    &__slide {
      height: 90vh;
      background: $black;
      position: relative;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__slide-content {
      position: absolute;
      bottom: 15px;
      width: calc(100vw - 30px);
      left: 15px;

      @include mq(md) {
        bottom: 100px;
        width: 100%;
        left: 10vw;
        width: 40%;
      }
    }

    &__thumbnails {
      position: absolute;
      right: 0;
      bottom: 100px;
      width: 700px;
      height: 300px;
      margin-bottom: -6px;
      margin-right: -15px;
      transform: translateX(50px);
      display: none;

      @include mq(md) {
        display: block;
      }

      .slick-slide {
        // width: 200px !important;
        // overflow: hidden !important;
        margin: 0 15px;
        height: 300px;
      }
    }

    &__thumbnail {
      overflow: hidden;
      position: relative;
    }

    &__thumbnail-content {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      padding: 20px;
    }

    &__thumbnail-subtitle {
      font-size: 16px;
      color: $white;
      font-family: $altFont;
      margin: 0;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    &__thumbnail-title {
      font-size: 24px;
      color: $white;
      font-family: $altFont;
      margin: 0;
    }

    &__subtitle {
      font-family: $altFont;
      color: $white;
      font-size: 24px;
      margin: 0;
      text-transform: uppercase;
    }

    &__title {
      font-family: $altFont;
      color: $white;
      font-size: 40px;
      margin: 0;
      
      @include mq(md) {
        font-size: 100px;
      }
    }
    
    &__text {
      line-height: 1.5  ;
      letter-spacing: 2px;
      color: $white;
      font-family: $altFont;
      margin: 0;
      padding-right: 20%;
      @include mq(md) {
        font-size: 20px;
      }
    }

    &__buttons {
      margin-top: 20px;
      @include mq(md) {
        display: flex;
        align-items: flex-start;
      }
    }

    &__button {
      display: flex;
      border: 1px solid $white;
      justify-content: center;
      padding: 5px 20px;
      background: $yellow;
      align-items: center;
      color: $black;
      background: $white;
      text-decoration: none;
      font-size: 16px;
      font-family: $altFont;
      white-space: nowrap;
      font-weight: 500;
      height: 45px;
      text-transform: uppercase;
      width: 100%;
      font-weight: 700;
      cursor: pointer;

      @include mq(md) {
        min-width: 200px;
        width: auto;
      }

      &--yellow {
        background: $yellow;
        border: 1px solid $yellow;

        &:hover {
          color: $yellow - #666;
        }
      }

      .icon {
        margin-right: 20px;
        display: flex;
        align-items: center;

        svg {
            @include icon(20px, 'up');
        }
      }
    }
  }

  &__episodios {
    padding: 20px 0;

    @include mq(md) {
      padding: 50px 0;
    }

    &__slider-container {
      margin-bottom: 40px;
    }

    &__slider-title {
      font-size: 24px;
      font-family: $altFont;
      font-weight: 700;
      margin-left: 25px;

      @include mq(md) {
        font-size: 40px;
        margin: 0;
        margin-bottom: 20px;
        margin-left: 45px;
      }
    }

    .slick-list {
      margin-left: -30px;
      margin-right: -30px;

      @include mq(md) {
        margin-left: 30px;
        margin-right: -15px;
      }
    }
    
    .slick-slide {
      margin: 0 5px;
      @include mq(md) {
        margin: 0 15px;
      }
    }

    .slick-prev {
      top: -55px;
      transform: scale(2);
      right: 145px;
      left: auto;
      background: url('/images/arrow-left.svg') no-repeat center center / cover;
      filter: invert(100%);

      &:before {
        content: '';
      }
    }

    .slick-next {
      background: url('/images/arrow-right.svg') no-repeat center center / cover;
      top: -55px;
      right: 45px;
      left: auto;
      transform: scale(2);
      filter: invert(100%);

      &:before {
        content: '';
      }
    }
  }
}
