.filter {
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid $black;

  &__controls {
    width: 100%;

    @include mq(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__search-button {
    height: 50px;
    border: 1px solid $black;
    background: transparent;
    position: relative;
    margin-bottom: 20px;

    @include mq(md) {
      width: calc(50% - 10px);
      margin-bottom: 0;
    }

    input {
      width: 100%;
      height: 100%;
      border: none;
      background: transparent;
      caret-color: $black;
      padding: 0 40px 0 20px;
      outline: none;
      color: $black;
      font-size: 26px;
      font-family: $mainFont;

      &::placeholder {
        color: $black;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    .icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      
      svg {
        @include icon(30px, 'right')
      }
    }
  }

  &__action-container {
    height: 50px;
    border: 1px solid $black;
    background: $black;
    position: relative;

    @include mq(md) {
      width: calc(50% - 10px);
    }

    .icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
      
      svg {
        @include icon(30px, 'down')
      }
    }
  }

  &__select {
    -webkit-appearance: none;
    color: $white;
    height: 100%;
    width: 100%;
    padding-left: 20px;
    outline: none;
    border: none;
    background: $black;
    cursor: pointer;
    font-size: 26px;
    font-family: $mainFont;
    text-transform: uppercase;
  }

  &__option {
    color: $white;
    font-family: $mainFont;
    background: $black;
  }

  &__sort-button {
    width: 100%;
    height: 100%;
    padding-left: 20px;
    text-align: left;
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 26px;
    font-family: $mainFont;
    text-transform: uppercase;
    color: $white;
  }
}