.artista-page {
  $a: &;
 
  &__container {
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  
    @include mq(md) {
      width: calc(100% - 90px);
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
  }

  &__artista-wrapper {
    width: 100%;
    margin-bottom: 50px;

    @include mq(md) {
      margin-bottom: 0;
      width: 75%;
    }
  }

  &__artista-image {
    width: 100%;
    height: 0;
    padding-bottom: 60%;
    overflow: hidden;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__artista-name {
    position: absolute;
    bottom: 60px;
    left: 20px;
    font-size: 40px;
    text-transform: uppercase;
    margin: 0;
    line-height: .8;
    
    @include mq(md) {
      bottom: 80px;
      left: 80px;
      font-size: 80px;
    }
  }

  &__social {
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    align-items: center;

    @include mq(md) {
      bottom: 80px;
      right: 80px;
      left: auto;
    }
  }

  &__social-title {
    font-family: $altFont;
  }

  &__social-list {
    display: flex;
  }

  &__social-item {
    margin-left: 20px;

    a {
      color: $white;
      font-size: 24px;
    }

    @include mq(md) {
      margin-left: 20px;
    }
  }

  &__artista-bio {
    padding: 20px;
    background: $white;

    @include mq(md) {
      padding: 80px;
    }
  }

  &__artista-text {
    margin: 0;
    color: $black;
    font-size: 16px;
    font-family: $altFont;
    line-height: 1.5;
    letter-spacing: 1.5px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    @include mq(md) {
      font-size: 20px;
    }
  }

  &__details-wrapper {
    width: 100%;
    
    @include mq(md) {
      width: 22%;
    }
  }

  &__details-group {
    margin-bottom: 40px;
  }

  &__details-title {
    color: $yellow;
    text-transform: uppercase;
    font-size: 18px;
    font-family: $altFont;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__details-logo {
    height: 60px;

    img {
      height: 100%;
    }
  }

  &__details-tags,
  &__details-proceso {
    font-family: $altFont;
  }

  &__otros-artistas {
    &__container {
      width: calc(100% - 30px);
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 50px;
    
      @include mq(md) {
        width: calc(100% - 90px);
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
      }
    }

    &__wrapper {
      width: 100%;
      cursor: pointer;

      @include mq(md) {
        width: calc(25% - 15px);
        &:not(:nth-child(4n + 4)) {
          margin-right: 20px;
        }
      }
    }
  }

  .-divider-container-artistas {
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
  
    @include mq(md) {
      width: calc(100% - 90px);
      display: flex;
    }
  }

  .-divider-artistas {
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $white;
  }
}