.artista {
  background: $lightestGray;

  &__hero {
    background: url('/images/home/hero.jpg') no-repeat center center / cover;
    @include mq(md) {
      padding-top: 15vh;
    }

    &__container {
      padding: 100px 0 50px 0;
    }

    &__title {
      text-transform: uppercase;
      font-family: $heroTitleFont;
      color: #000;
      mix-blend-mode: screen;
      -webkit-text-stroke: 1px $white;
      font-size: 50px;
      margin-bottom: 40px;
      margin: 0;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      background: url('/images/transparent.png') repeat center center;
      
      @include mq(md) {
        padding-left: 10px;
        font-size: 110px;
      }
    }

    &__text {
      color: $white;
      font-size: 40px;
      text-transform: uppercase;
      margin: 0;
      display: inline-block;
      position: relative;

      @include mq(md) {
        font-size: 80px;
      }
    }

    &__wrapper {
      @include mq(md) {
        display: flex;
        justify-content: space-between;

      }
    }

    &__buttons {
      display: flex;
      align-items: flex-start;
      margin-top: 20px;
    }

    &__scroll-button {
      background: transparent;
      border: none;
      cursor: pointer;

      .icon {
        margin-right: 20px;

        @include mq(md) {
          margin-right: 40px;
        }

        svg {
            @include icon(60px, 'down');
        }
      }
    }

    &__button {
      display: inline-flex;
      justify-content: center;
      padding: 5px 20px;
      background: $black;
      align-items: center;
      color: $white;
      text-decoration: none;
      font-size: 16px;
      font-family: $altFont;
      white-space: nowrap;
      
      .icon {
        margin-left: 10px;

        svg {
            @include icon(30px, 'rightup');
        }
      }
    }

    &__social {
      display: flex;
      align-items: center;
    }

    &__social-text {
      color: $white;
      font-family: $altFont;
      font-size: 20px;
    }

    &__social-list {
      display: flex;
    }

    &__social-item {
      margin-left: 40px;

      a {
        color: $white;
        font-size: 24px;
      }
    }
  }

  &__details {
    padding-top: 50px;

    &__content-heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;

      @include mq(md) {
      }

      p {
        font-family: $altFont;
        font-weight: 500;
        font-size: 20px;
        width: 100%;
        line-height: 1.5;
        letter-spacing: 2px;
        margin: 0;

        @include mq(md) {
          font-size: 30px;
          width: 65%;
        }
      }
    }

    &__emblem-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      width: 35%;

      img {
        width: 50px;

        @include mq(md) {
          width: 120px;
          height: auto;
        }
      }
    }


    &__content {
      p {
        font-family: $altFont;
        letter-spacing: 2px;
        line-height: 1.5;
        font-size: 16px;

        @include mq(md) {
          font-size: 20px;
        }
      }
    }
  }

  &__residencias {
    @include mq(md) {
      padding-bottom: 50px;
    }

    &__container {
      width: calc(100% - 30px);
      margin-left: auto;
      margin-right: auto;
      border-bottom: 1px solid $black;
      
      @include mq(md) {
        padding-bottom: 40px;
        padding-top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        width: calc(100% - 90px);
      }
    }

    &__wrapper {
      width: 100%;
      
      @include mq(md) {
        padding: 0 20px;
        width: 30%;
      }
    }
  }

  &__otros-artistas {
    padding-bottom: 40px;

    &__container {
      width: calc(100% - 30px);
      margin-left: auto;
      margin-right: auto;
      padding-top: 50px;
      padding-bottom: 40px;
      border-bottom: 1px solid $black;
  
      @include mq(md) {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: calc(100% - 90px);
      }
    }

    &__wrapper {
      width: 100%;
      padding: 0 60px;

      &:not(:last-child) {
        margin-bottom: 40px;
      }
      
      @include mq(md) {
        padding: 0 20px;

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }

    .artista-module {
      &__title,
      &__name {
        color: $black;
      }
    }
  }
  
  .-divider-container {
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;

    @include mq(md) {
      width: calc(100% - 90px);
    }
  }

  .-divider {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $black;
  
    @include mq(md) {
      padding-bottom: 40px;
    }
  }
}
