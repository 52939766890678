.tacto {
  background: $black;
  color: $white;
  padding-bottom: 50px;

  &__hero {
    width: 100%;
    padding-top: 15vh;
    position: relative;

    img {
      width: 100%;
    }

    #artistas,
    #coproductores {
      position: absolute;
      left: 0;
      bottom: 65px;

      @include mq(md) {
        bottom: 115px;
      }
    }
  }

  &__content {
    &__heading {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    &__tabs{
      @include mq(md) {
        display: flex;
      }
    }

    &__tab {
      @include trans;
      color: $white;
      font-family: $altFont;
      font-size: 18px;
      text-transform: uppercase;
      cursor: pointer;
      font-weight: 500;

      @include mq(md) {
        &:not(:first-child) {
          margin-left: 20px;
        }
      }

      &.-active {
        color: $yellow;
      }
    }

    &__wrapper {
      position: relative;
      width: 100%;
      overflow: hidden;

      @include mq(md) {
        // margin-bottom: 50px;
      }
    }

    &__container {
      @include trans;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      pointer-events: none;

      &.-active {
        opacity: 1;
        pointer-events: all;
      }
    }

    &__title {
      color: $yellow;
      font-size: 60px;
      text-transform: uppercase;
      margin: 0;
      line-height: .7;
      margin-bottom: 40px;
      margin-top: 20px;

      @include mq(md) {
        margin-bottom: 100px;
        margin-top: 100px;
        font-size: 80px;
      }
    }

    &__main-text {
      color: $yellow;
      font-family: $altFont;
      font-size: 20px;
      font-weight: 500;
      margin: 0;
      letter-spacing: 2px;
      line-height: 1.3;
      margin-bottom: 100px;

      @include mq(md) {
        margin-bottom: 100px;
        margin-top: 100px;
        font-size: 30px;
      }
    }
    
    &__video {
      width: calc(100% - 30px);
      margin-left: auto;
      margin-right: auto;
      height: 0;
      padding-bottom: 56.5%;
      overflow: hidden;
      position: relative;

      @include mq(md) {
        width: calc(100% - 90px);
      }

      iframe {
        position: absolute;
        border: none;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }

    .banner {
      margin-bottom: 20px;
  
      @include mq(md) {
        margin-bottom: 50px;
      }

      span {
        color: $white;
      }
    }

    &__sesiones {
      width: calc(100% - 30px);
      margin-left: auto;
      margin-right: auto;

      @include mq(md) {
        width: calc(100% - 150px);
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
      }
    }

    &__sesion {
      width: 100%;

      img {
        width: 100%;
      }
    }

    &__sesion-container {
      width: 100%;

      @include mq(md) {
        padding: 0 30px;
      }
    }

    &__container-heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &__container-wrapper {
      min-height: 100px;

      span {
        display: block;
        font-family: $altFontSlim;
        text-transform: uppercase;
      }
    }

    &__heading-subtitle {
      font-size: 26px;
      font-weight: 300;
    }

    &__heading-title {
      font-size: 30px;
      font-weight: 500;
    }

    &__text-container {
      padding: 10px;
      position: relative;
      min-height: 130px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        height: 100%;
        border:2px solid $white;
      }
    }

    &__text {
      text-align: center;
      padding: 10px;
      background: $black;
      text-transform: uppercase;
      font-size: 16px;
      font-family: $altFont;
      z-index: 2;
      position: relative;
    }

    &__date  {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      
    }
    
    &__date-wrapper {
      text-align: center;
      display: flex;

      span {
        display: block;
        margin-right: 8px;
      }

      div {
        letter-spacing: 8px;
      }
    }

    &__year {
      text-align: center;
      letter-spacing: 11px;
      width: 100%;
    }

    &__image-hours {
      position: absolute;
      bottom: 20px;
      left: 20px;
      font-family: $altFont;
      text-transform: uppercase;
      font-size: 12px;
    }

    &__videos {
      width: calc(100% - 30px);
      margin-left: auto;
      margin-right: auto;

      @include mq(md) {
        width: calc(100% - 150px);
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
      }
    }

    &__video-container {
      width: 100%;
      height: 0;
      padding-bottom: 56.5%;
      overflow: hidden;
      position: relative;
      margin-bottom: 20px;

      @include mq(md) {
        width: 32%;
        padding-bottom: 20%;
        margin-bottom: 0;
      }

      iframe {
        position: absolute;
        border: none;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }

    &__artistas {
      width: calc(100% - 30px);
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
    
      @include mq(md) {
        width: calc(100% - 90px);
        display: flex;
        flex-wrap: wrap;
      }
    }

    &__artista {
      width: 100%;
      margin-bottom: 20px;

      @include mq(md) {
        width: calc(25% - 15px);

        &:not(:nth-child(4n + 4)) {
          margin-right: 20px;
        }
      }
    }

    &__coproductores-container {
      width: calc(100% - 30px);
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-wrap: wrap;
      
      @include mq(md) {
        width: calc(100% - 90px);
      }
    }

    &__coproductor {
      width: 100%;
      cursor: pointer;

      @include mq(md) {
        width: calc(25% - 25px);
        &:not(:nth-child(4n + 4)) {
          margin-right: 33px;
        }
      }
    }

    &__images {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      overflow: hidden;
      position: relative;
      margin-bottom: 20px;

      &:hover {
        img {
          &.-gif {
            opacity: 1;
          }

          &.-logo {
            opacity: 0;
          }
        }
      }

      img {
        &.-logo {
          @include trans;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
        }

        &.-gif {
          @include trans;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    &__coproductor-title {
      text-align: center;
      display: block;
      color: $white;
      font-size: 50px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }

  .-divider-container {
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;

    @include mq(md) {
      width: calc(100% - 90px);
    }
  }

  .-divider {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $white;
  
    @include mq(md) {
      padding-bottom: 40px;
      margin-bottom: 40px;
    }
  }
}