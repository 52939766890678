.episodio {
  background: $lightestGray;
  overflow: hidden;

  &__hero {
    background: url('/images/nave-tv/hero.jpg') no-repeat center center / cover;
    @include mq(md) {
      padding-top: 15vh;
    }

    &__container {
      padding: 100px 0;
      width: 60%;
    }

    &__subtitle {
      font-family: $altFont;
      color: $white;
      font-size: 24px;
      margin: 0;
      text-transform: uppercase;
    }

    &__title {
      font-family: $altFont;
      color: $white;
      font-size: 20px;
      margin: 0;
      
      @include mq(md) {
        font-size: 100px;
      }
    }
    
    &__text {
      line-height: 1.5  ;
      letter-spacing: 2px;
      color: $white;
      font-family: $altFont;
      margin: 0;
      padding-right: 20%;
      @include mq(md) {
        font-size: 20px;
      }
    }

    &__buttons {
      display: flex;
      align-items: flex-start;
      margin-top: 20px;
    }

    &__button {
      display: flex;
      border: 1px solid $white;
      justify-content: center;
      padding: 5px 20px;
      background: $yellow;
      align-items: center;
      color: $black;
      background: $white;
      text-decoration: none;
      font-size: 16px;
      font-family: $altFont;
      white-space: nowrap;
      font-weight: 500;
      height: 45px;
      text-transform: uppercase;
      min-width: 200px;
      font-weight: 700;
      cursor: pointer;

      &--yellow {
        background: $yellow;
        border: 1px solid $yellow;

        &:hover {
          color: $yellow - #666;
        }
      }

      .icon {
        margin-right: 20px;
        display: flex;
        align-items: center;

        svg {
            @include icon(20px, 'up');
        }
      }
    }
  }

  &__description {
    padding: 20px 0;

    @include mq(md) {
      padding: 50px 0;
    }

    &__text {
      font-family: $altFont;
      font-size: 24px;
      font-weight: 500;
      margin: 0;
    }

    &__crew {
      font-family: $altFont;
      font-size: 20px;
      display: block;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__related {
    padding: 20px 0;

    @include mq(md) {
      padding: 100px 0;
    }

    &__title {
      font-size: 40px;
      font-family: $altFont;
      margin: 0;
      margin-bottom: 20px;
    }

    &__container {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $lightGray;

        @include mq(md) {
          margin-bottom: 40px;
          padding-bottom: 40px;
        }
      }

      @include mq(md) {
        display: flex;
        flex-wrap: nowrap;

        &:not(:last-child) {
            margin-bottom: 40px;
            padding-bottom: 40px;
        }
      }
    }

    &__image {
      width: 100%;
      height: 0;
      overflow: hidden;
      position: relative;
      padding-bottom: 60%;
      
      @include mq(md) {
        padding-bottom: 20%;
        width: 33.33%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__content {
      width: 100%;
      padding: 15px 0;

      @include mq(md) {
        width: 66.66%;
        padding: 0 30px;
      }
    }

    &__content-title {
      font-size: 24px;
      font-family: $altFont;
      margin: 0;
      margin-bottom: 20px;

      @include mq(md) {
      }
    }

    &__content-text {
      font-size: 20px;
      font-family: $altFont;
      margin: 0;
    }
  }
}
