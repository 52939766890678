.footer {
  background: $darkGray;
  padding: 50px 0;
  font-family: $altFont;
  position: relative;
  z-index: 2;

  .row-border {
    padding-bottom: 50px;
    margin-bottom: 50px;
    border-bottom: 1px solid $white;
  }

  &__image {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;

    @include mq(md) {
      margin-bottom: 0;
    }

    img {
      &.-background {
        mix-blend-mode: screen;
      }
    }
  }

  &__emblem {
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq(md) {
      margin-bottom: 0;
    }

    img {
      filter: invert(100%);
      width: 30%;

      @include mq(md) {
        width: 70%;
      }
    }
  }

  &__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;

    @include mq(md) {
      margin-bottom: 0;
    }
  }

  &__list {
    &__item {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      a {
        color: $white;
        font-size: 16px;
        text-decoration: none;
        font-weight: 700;
      }
    }
  }

  &__newsletter {
    text-align: center;
    margin-bottom: 40px;

    @include mq(md) {
      text-align: right;
      margin-bottom: 0;
    }

    &__title {
      color: $white;
      font-size: 18px;
      margin-bottom: 40px;
      display: block;
      font-weight: 700;
    }
  }

  &__social {
    display: flex;
    justify-content: center;

    @include mq(md) {
      justify-content: flex-end;
    }
  }

  &__social-item {
    margin: 0 20px;

    @include mq(md) {
      margin: 0;
    }

    &:not(:first-child) {
      @include mq(md) {
        margin-left: 40px;
      }
    }

    a {
      text-decoration: none;
      font-size: 30px;
      color: $white;
    }
  }

  &__go-top {
    .icon{
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        @include icon($size: 80px)
      }
    }
  }
}
