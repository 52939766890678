.servicios {
  background: $lightestGray;

  &__hero {
    background: url('/images/convocatorias/single-hero.jpg') no-repeat center center / cover;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $black;
      opacity: .5;
    }

    @include mq(md) {
      padding-top: 15vh;
    }

    &__container {
      padding: 100px 0;
      z-index: 2;
      position: relative;
    }

    &__title {
      text-transform: uppercase;
      font-family: $heroTitleFont;
      color: #000;
      mix-blend-mode: screen;
      -webkit-text-stroke: 1px $white;
      font-size: 40px;
      margin-bottom: 40px;
      margin: 0;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      background: url('/images/transparent.png') repeat center center;
      
      @include mq(md) {
        padding-left: 10px;
        font-size: 110px;
      }
    }

    &__buttons {
      display: flex;
      align-items: flex-start;
      margin-top: 20px;
    }

    &__scroll-button {
      background: transparent;
      border: none;
      cursor: pointer;

      .icon {
        margin-right: 20px;

        @include mq(md) {
          margin-right: 40px;
        }

        svg {
            @include icon(60px, 'down');
        }
      }
    }

    &__button {
      display: inline-flex;
      justify-content: center;
      padding: 5px 20px;
      background: $black;
      align-items: center;
      color: $white;
      text-decoration: none;
      font-size: 16px;
      font-family: $altFont;
      white-space: nowrap;
      
      .icon {
        margin-left: 10px;

        svg {
            @include icon(30px, 'rightup');
        }
      }
    }
  }

  &__details {
    padding-top: 50px;

    &__breadcrum {
      margin-bottom: 40px;
      ul {
        display: flex;

        li {
          text-transform: uppercase;
          font-family: $altFont;

          &:not(:last-child) {
            margin-right: 10px;
            padding-right: 10px;
            border-right: 1px solid $black;
          }
        }
      }
    }

    &__content-heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;

      p {
        font-family: $altFont;
        font-weight: 500;
        font-size: 20px;
        width: 100%;
        line-height: 1.5;
        letter-spacing: 2px;
        margin: 0;

        @include mq(md) {
          font-size: 32px;
          width: 65%;
        }
      }
    }

    &__emblem-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      img {
        width: 50px;

        @include mq(md) {
          width: 100%;
          height: auto;
        }
      }
    }

    &__text {
      font-family: $altFont;
      letter-spacing: 2px;
      line-height: 1.5;
      font-size: 16px;
      margin: 0;
      margin-bottom: 40px;

      @include mq(md) {
        font-size: 20px;
      }
    }

    &__subtitle {
      font-family: $altFont;
      letter-spacing: 2px;
      line-height: 1.5;
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      margin-bottom: 40px;

      @include mq(md) {
        font-size: 20px;
      }
    }

    &__nombre {
      font-family: $altFont;
      letter-spacing: 2px;
      line-height: 1.5;
      font-size: 16px;
      margin: 0;
      text-transform: uppercase;
      margin-bottom: 10px;

      @include mq(md) {
        font-size: 20px;
      }
    }

    &__container {
      display: flex;
    }

    &__container-label {
      font-weight: 500;
      font-family: $altFont;
      margin-right: 10px;
      font-size: 20px;
    }

    &__container-text {
      font-family: $altFont;
      font-size: 20px;
    }
  }

  &__fechas {
    padding-bottom: 50px;
  }

  &__contacto {
    padding-bottom: 50px;

    &__container {
      background: $white;
      padding: 10px 20px;
      position: relative;
      margin-bottom: 20px;

      @include mq(md) {
        padding: 20px 40px;
      }
    }
  
    &__content {
      width: 100%;
      margin-bottom: 30px;

      @include mq(md) {
        margin-bottom: 60px;
      }
    }
  
    &__subtitle {
      margin: 0;
      text-transform: uppercase;
      font-size: 24px;
      font-family: $heroTitleFont;
  
      @include mq(md) {
        font-size: 60px;
      }
    }
  
    &__title {
      margin: 0;
      text-transform: uppercase;
      font-size: 20px;
  
      @include mq(md) {
        font-size: 40px;
      }
    }

    &__text {
      font-family: $altFont;
      width: 70%;
      font-size: 20px;
      line-height: 1.5;
    }
  
    img {
      width: 70px;
      height: 70px;
      position: absolute;
      right: 30px;
      bottom: 40px;
  
      @include mq(md) {
        width: 70px;
        height: 70px;
        position: absolute;
        right: 50px;
        top: 20px;
      }
    }
    
    &__form-group {
      height: 50px;
      width: 100%;
      margin-bottom: 20px;
      position: relative;
      display: flex;
      align-items: center;

      .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        pointer-events: none;

        svg {
          @include icon(30px, 'down')
        }
      }

      &--calendar {
        .icon {
          svg {
            @include icon(30px, 'up')
          }
        }
      }

      &--button {
        .icon {
          svg {
            @include icon(30px, 'rightup')
          }
        }
      }
    }

    &__input {
      border: 1px solid $white;
      background: $white;
      height: 100%;
      width: 100%;
      font-size: 24px;
      padding: 0 15px;
      text-transform: uppercase;
      font-family: $mainFont;
      color: $black;

      &::placeholder {
        color: $black;
      }

      &--checkbox {
        position: absolute;
        left: -9999px;

        &:checked + label {
          &:after {
            opacity: 1;
          }

          &:before {
            border: 1px solid $black;
          }
        }
      }
    }

    &__select {
      border: 1px solid $white;
      background: $white;
      height: 100%;
      width: 100%;
      font-size: 24px;
      padding: 0 15px;
      text-transform: uppercase;
      font-family: $mainFont;
      color: $black;
      -webkit-appearance: none;
    }

    &__label {
      font-size: 24px;
      padding: 0 15px;
      text-transform: uppercase;
      font-family: $mainFont;
      color: $black;
      padding-left: 45px;
      display: flex;
      position: relative;
      white-space: nowrap;

      &:after {
        content: '';
        position: absolute;
        left: -5px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        transform-origin: center;
        height: 1px;
        width: 40px;
        background: $black;
        opacity: 0;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        border: 1px solid $white;
        background: $white;
      }
    }

    &__form-subtitle {
      font-size: 24px;
      text-transform: uppercase;
      font-family: $mainFont;
      color: $black;
      margin: 0;
      margin-bottom: 20px;
      display: block;
    }

    &__submit {
      background: $black;
      display: inline-flex;
      align-items: center;
      padding-left: 20px;
      width: 100%;
      height: 100%;
      border: 1px solid $black;
      color: $white;
      font-weight: 700;
    }
  }
  
  .-divider {
    margin-bottom: 20px;
    padding-bottom: 70px;
    border-bottom: 1px solid $black;
  }

  .-form-divider {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $black;
  }
}
